<template>
  <div class="page3">
    <div class="mainBox">
		<div class="num num1">
			<div>{{Language[selectLan]["总发行量"]}}</div>
			<div>{{(dataStat2[3]+dataStat2[4]).toFixed(0)}}</div>
		</div>
		<div class="num num2">
			<div>{{Language[selectLan]["剩余总量"]}}</div>
			<div>{{dataStat2[3].toFixed(2)}}</div>
		</div>
		<div class="num num3">
			<div>{{Language[selectLan]["总燃烧量"]}}</div>
			<div>{{dataStat2[4].toFixed(2)}}</div>
		</div>
		<div class="num num4">
			<div>{{Language[selectLan]["总燃烧次数"]}}</div>
			<div>{{dataStat2[9]}}</div>
		</div>
		<div class="num num5">
			<div style="color:#F5B22F">{{dataStat[1].toFixed(2)}}</div>
			<div style="color:#ffffff3b">{{Language[selectLan]["今日税点"]}}</div>
		</div>
		<div class="num num6">
			<div style="color:#F5B22F">{{dataStat[9]}}</div>
			<div style="color:#ffffff3b">{{Language[selectLan]["总燃烧地址数"]}}</div>
		</div>
	</div>
	<div class="bigNum">
		{{( (dataStat2[3]) / (dataStat2[0]/dataStat2[1]) ).toFixed(2)}}BNB
	</div>

	<div class="henxian">
		<span class="line"></span>
		<span>Welcome to PMM</span>
		<span class="line"></span>

	</div>

	

  </div>
</template>

<script>
export default {
  name: 'MaterialList',
  props: {
    // 素材数据
    dataStat: {type: Array,default() {return []}},
	dataStat2: {type: Array,default() {return []}},
	Language: {default() {return {}}},
	selectLan: {default() {return 'en'}},
    
  },
  data() {
    return {
      
      urls: []
    }
  },
  computed: {
    
  },
  created() {

  },
  mounted() {
    
  },
  methods: {
    sureUrls() {
      
    }
  }
}
</script>

<style>
.page3 .mainBox {
  background-image: url("../../static/page3bg.png");
  background-size: 100% 100%;
  width: 90%;
  height: 250px;
  margin: 0 auto;
  margin-top: 20%;
  position: relative;
}

.num{
	color: #ffffff61;

}
.num div:last-child{
	color: #F5B22F;
	margin-top: 2px;
}

.num1{
	transform: translateY(-13px);
	margin-left: 20px;
}

.num2{
 position: absolute;
 right: 20px;
 top: -12px;
}

.num3{
	position: absolute;
	top: 80px;
	left: 25px;
   }

   .num4{
	position: absolute;
	top: 80px;
	right: 10px;
   }

   .num6{
	position: absolute;
	bottom: 0;
	right: 10px;
	text-align: right;
   }

   .num5{
	position: absolute;
	left: 0;
	bottom: 10px;
   }

   .bigNum{
	font-size: 26px;
font-weight: 600;
color: #F5B22F;
text-align: center;
margin-top: 10px;
   }

   .henxian{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 90%;
	margin: 0 auto;
	margin-top: 10%;
	color:#ffffff61 ;
   }

   .henxian .line{
     width: 25%;
	 height: 1px;
	 background: #ffffff61;
   }
</style>

